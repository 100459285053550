import React from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import Amplify, { Auth } from "aws-amplify";
import { Error, Success } from "../../components/Alerts";
import ShowCompanyDetails from "./ShowCompanyDetails";
import Api from "../../config/Api";
import { Redirect } from "react-router-dom";
import { Image } from "semantic-ui-react";
import PlaceholderList from "../components/Placeholder";
import {
  configureAmplify,
  SetS3Config,
} from "../../components/aws-s3-doc/server.js";

import { Storage } from "aws-amplify";
import ChatList from "../components/Chatlist";

Amplify.configure(configureAmplify);

class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "Company",
      data: {},
      error: "",
      success: false,
      loading: true,
    };
  }

  componentDidMount() {
    SetS3Config(
      "miloadapp47a39c2ae0314264824214b5a4ef023e114005-dev",
      "protected"
    );
    let imageKeys = Storage.list("")
      .then((res) => {
        console.log("dsjkfjfksfjds", res);
      })
      .catch((err) => {
        console.log("dsjkfjfksfjds", err);
      });

    // let data = {id:this.props.match.params.id};
    let data = { params: { user_id: this.props.match.params.id } };
    Api.get("user", data)
      .then((response) => {
        console.log(response.data.data);
        let response_data = response.data.data;
        this.setState({
          data: response_data,
          loading: false,
        });
        console.log(this.state.data);
      })
      .catch((e) => {
        console.info(e);
        //   this.setState({
        //   error:e.response.data.message,
        //   loading:false
        // })
      });
  }

  // getFile = (file) => {
  //   SetS3Config(
  //     "miloadapp47a39c2ae0314264824214b5a4ef023e114005-dev",
  //     "protected"
  //   );
  //   setImage1(displayImage(event));
  //   setShowImage1(true);
  // };

  render() {
    if (this.state.loading === true) {
      return (
        <table>
          <PlaceholderList colspan="colspan" />
        </table>
      );
    }
    return (
      <Row className="mt-4">
        {/* <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col> */}
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>{this.state.user_type} Details</Card.Header>
            <Card.Body>
              <ShowCompanyDetails variant="user" data={this.state.data} />
            </Card.Body>
          </Card>
        </Col>
        {/* <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Products ({this.state.data.products.length})</Card.Header>
              <Card.Body>

              <ChatList variant={1} chat={this.state.data.products }  ></ChatList>
              </Card.Body>
            </Card>
        </Col> */}
      </Row>
    );
  }
}

export default CompanyView;
