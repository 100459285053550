import React from "react";
import { Icon, Label, Menu, Table, Card, Button } from "semantic-ui-react";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const MyRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>
      <p>{props.value}</p>
    </td>
  </tr>
);

const LinkRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>
      <Link to={`/${props.path}/${props.value}`}>Open</Link>
    </td>
  </tr>
);

const RowElement = (props) => (
  <span>
    {props.value}
    <br></br>
  </span>
);

const ShowJobDetails = (props) => (
  <>
    <table class="table">
      <tbody>
        <MyRow lable="Job ID" value={props.data.job_number} />
        <MyRow
          lable="Posted On"
          value={moment(props.data.created_at).format("Do MMM YY")}
        />

        <MyRow lable="Job pickup location" value={props.data.pickup_address} />
        <tr>
          <th scope="row">Open pickup Location in Map</th>
          <td>
            <a
              target="_blank"
              href={
                "http://maps.google.com/?q=" +
                props.data.pickup_location_point.coordinates[0] +
                "," +
                props.data.pickup_location_point.coordinates[1]
              }
            >
              Open Location in Map
            </a>
          </td>
        </tr>
        <MyRow
          lable="Job dropoff location"
          value={props.data.dropoff_address}
        />
        <tr>
          <th scope="row">Open dropoff Location in Map</th>
          <td>
            <a
              target="_blank"
              href={
                "http://maps.google.com/?q=" +
                props.data.dropoff_location_point.coordinates[0] +
                "," +
                props.data.dropoff_location_point.coordinates[1]
              }
            >
              Open Location in Map
            </a>
          </td>
        </tr>

        <MyRow lable="Rate per mt" value={props.data.commodity_price_per_mt} />
        <MyRow
          lable="Transportation per mt"
          value={props.data.transportation_rate_per_mt}
        />
        <MyRow lable="Pickup Date" value={props.data.pickup_date} />
        <MyRow lable="Dropoff Date" value={props.data.dropoff_date} />
        <MyRow lable="Offloading Number" value={props.data.offloading_number} />
        <MyRow lable="Offloading Type" value={props.data.offloading_type} />
        <MyRow lable="Job Status" value={props.data.job_status} />
        <tr>
          <th scope="row">Job description</th>
          <RowElement
            lable="Job description"
            value={props.data.job_description}
          />
        </tr>
        <LinkRow
          lable="Shipper"
          path="shipper"
          value={props.data.shipper.uuid}
        />

        <tr></tr>

        {/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}

        {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}

        <br></br>
      </tbody>
    </table>
    {/* <iframe 
  width="700" 
  height="400" 
  frameborder="0" 
  scrolling="no" 
  marginheight="0" 
  marginwidth="0" 
  src="https://maps.google.com/maps?q=30.6804481,76.726084&hl=es&z=14&amp;output=embed"
    >
      
 </iframe> */}
  </>
);

export default ShowJobDetails;
