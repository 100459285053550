import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from "react-router-dom";
import { Routes } from "../routes";


// pages


// MiLoad pages


import AdminAdd from './Admins/CreateAdmin'
import AdminList from './Admins/ListAdmin'

//locations
import LocationList from './Locations/ListLocation'
import LocationAdd from './Locations/CreateLocation'

//Loads
import LoadList from './Loads/ListLoad'
import LoadAdd from './Loads/CreateLoad'

//Users
// // Shipper
import ShipperList from './Shipper/ShipperList'
import ShipperView from './Shipper/ShipperView'


import CompanyList from './Company/CompanyList'
import CompanyView from './Company/CompanyView'


import VehicleOwnerList from './Owner/VehicleOwnerList'
import DriverList from './Driver/DriverList'


import JobList from './Jobs/JobList'
import JobPendingList from './Jobs/JobPendingList'
import JobOngoingList from './Jobs/JobOngoingList'
import JobCompletedList from './Jobs/JobCompletedList'
import JobCancelledList from './Jobs/JobCancelled'
import JobView from './Jobs/JobView'

////////////////////////////////

import DashboardOverview from "./dashboard/DashboardOverview";
import UserList from './User/UserList'
import EditAdminDetails from './User/EditAdminDetails'

import ComplaintList from './enquiry/ListComplaint'
import EnquiryList from './enquiry/ListEnquiry'


import UserView from './User/UserView'
import SitterView from './User/SitterView'
import ChatView from './Chat/Chatview'
import TeacherList from './Teacher/Teacher'
import TeacherUnverified from './Teacher/TeacherUnverified'
import TeacherView from './Teacher/TeacherView'
import ScrollToTop from "../components/ScrollToTop";


import SubjectAdd from './Subject/CreateSubject'
import SubjectEdit from './Subject/EditSubject'
import SubjectList from './Subject/ListSubject'

import CategoryList from './Category/ListCategory'
import CategoryAdd from './Category/CreateCategory'
import CategoryEdit from './Category/EditCategory'


import AdvertisementList from './Advertisement/ListAdvertisement'
import AdvertisementAdd from  './Advertisement/CreateAdvertisement'
import AdvertisementEdit from './Advertisement/EditAdvertisement'

// import ManufacturerList from './Manufacturer/ListManufacturer'
// import ManufacturerAdd from './Manufacturer/CreateManufacturer'
// import ManufacturerEdit from './Manufacturer/EditManufacturer'

import FinanceOuotesList from './Quotes/FinanceOuotesList'
import InsuranceOuotesList from './Quotes/InsuranceOuotesList'
import TransportationOuotesList from './Quotes/TransportationOuotesList'

import UserQueryList from './Quotes/QueryList'

import IndustryTypeList from './IndustryType/ListIndustryType'
import IndustryTypeAdd from './IndustryType/CreateIndustryType'
import IndustryTypeEdit from './IndustryType/EditIndustryType'

import CountryAdd from './Country/CreateCountry'
import CountryEdit from './Country/EditCountry'
import CountryList from './Country/ListCountry'

import Signin from "./auth/Signin";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";





// documentation pages


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { extend } from 'chartist';



const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        {/* <Preloader show={loaded ? false : true} /> */}
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};


class HomePage extends React.Component{
  render(){

let token = localStorage.getItem('token');
if(token == 'false' || token == '' || token == null) {
return (
  <HashRouter>
      <ScrollToTop />
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
     <Redirect to={Routes.Signin.path} />
  </Switch>
  </HashRouter>);
}

 return(
  <HashRouter>
      <ScrollToTop />
  <Switch>

    {/*  Auth   */}
   
    
       {/*  Dashboard  */}
       
       {/** Miload */}
       <RouteWithSidebar exact path={Routes.AdminAdd.path} component={AdminAdd} />
       <RouteWithSidebar exact path={Routes.AdminList.path} component={AdminList} />
       
       <RouteWithSidebar exact path={Routes.LocationList.path} component={LocationList} />
       <RouteWithSidebar exact path={Routes.LocationAdd.path} component={LocationAdd} />


       <RouteWithSidebar exact path={Routes.LoadList.path} component={LoadList} />
       <RouteWithSidebar exact path={Routes.LoadAdd.path} component={LoadAdd} />

       <RouteWithSidebar exact path={Routes.ShipperList.path} component={ShipperList} />
       <RouteWithSidebar exact path={Routes.ShipperView.path} component={ShipperView} />
       

       <RouteWithSidebar exact path={Routes.CompanyList.path} component={CompanyList} />
       <RouteWithSidebar exact path={Routes.CompanyView.path} component={CompanyView} />


       <RouteWithSidebar exact path={Routes.DriverList.path} component={DriverList} />
       <RouteWithSidebar exact path={Routes.VehicleOwnerList.path} component={VehicleOwnerList} />
       {/** Miload */}
       

    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={UserList} />

    <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />
       
       <RouteWithSidebar exact path={Routes.EditAdminDetails.path} component={EditAdminDetails} />
       

    <RouteWithSidebar exact path={Routes.ComplaintList.path} component={ComplaintList} />
    <RouteWithSidebar exact path={Routes.EnquiriesList.path} component={EnquiryList} />


    <RouteWithSidebar exact path={Routes.UserView.path} component={UserView} />
    <RouteWithSidebar exact path={Routes.SitterView.path} component={SitterView} />
    <RouteWithSidebar exact path={Routes.ChatView.path} component={ChatView} />
    
    <RouteWithSidebar exact path={Routes.JobList.path} component={JobList} />
    <RouteWithSidebar exact path={Routes.JobPendingList.path} component={JobPendingList} />
    <RouteWithSidebar exact path={Routes.JobOngoingList.path} component={JobOngoingList} />
    <RouteWithSidebar exact path={Routes.JobCompletedList.path} component={JobCompletedList} />
    <RouteWithSidebar exact path={Routes.JobCancelledList.path} component={JobCancelledList} />
    <RouteWithSidebar exact path={Routes.JobView.path} component={JobView} />
       
    <RouteWithSidebar exact path={Routes.TeacherList.path} component={TeacherList} />
    <RouteWithSidebar exact path={Routes.TeacherListUnverified.path} component={TeacherUnverified} />
    <RouteWithSidebar exact path={Routes.TeacherView.path} component={TeacherView} />

    <RouteWithSidebar exact path={Routes.SubjectAdd.path} component={SubjectAdd} />
    <RouteWithSidebar exact path={Routes.SubjectEdit.path} component={SubjectEdit} />
    <RouteWithSidebar exact path={Routes.SubjectView.path} component={SubjectList} />
    
    <RouteWithSidebar exact path={Routes.CategoryAdd.path} component={CategoryAdd} />
    <RouteWithSidebar exact path={Routes.CategoryView.path} component={CategoryList} />
    <RouteWithSidebar exact path={Routes.CategoryEdit.path} component={CategoryEdit} />

    {/* Qoutes */}
    <RouteWithSidebar exact path={Routes.InsuranceListView.path} component={InsuranceOuotesList} />
    <RouteWithSidebar exact path={Routes.FinanceListView.path} component={FinanceOuotesList} />
    <RouteWithSidebar exact path={Routes.TransportationListView.path} component={TransportationOuotesList} />

    <RouteWithSidebar exact path={Routes.UserQueryView.path} component={UserQueryList} />

    

    <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
    <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
    <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />


    <RouteWithSidebar exact path={Routes.AdvertisementAdd.path} component={AdvertisementAdd} />
    <RouteWithSidebar exact path={Routes.AdvertisementEdit.path} component={AdvertisementEdit} />
    <RouteWithSidebar exact path={Routes.AdvertisementView.path} component={AdvertisementList} />


    <RouteWithSidebar exact path={Routes.IndustryTypeAdd.path} component={IndustryTypeAdd} />
    <RouteWithSidebar exact path={Routes.IndustryTypeEdit.path} component={IndustryTypeEdit} />
    <RouteWithSidebar exact path={Routes.IndustryTypeView.path} component={IndustryTypeList} />
    
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
  <Redirect to={Routes.CompanyList.path} />
  </Switch>
  </HashRouter>
);
  }
}

export default HomePage;


// export default () => {
// const [token, setToken] = useState();
// if(!token) {
// return (
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
//      <Redirect to={Routes.Signin.path} />
//   </Switch>
//   </BrowserRouter>);
   
// }

//  return(
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>

//     {/*  Auth   */}
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    
//     {/*  Dashboard  */}

//     <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
//     <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />
//     <RouteWithSidebar exact path={Routes.TeacherList.path} component={TeacherList} />

//     <RouteWithSidebar exact path={Routes.SubjectAdd.path} component={SubjectAdd} />
//     <RouteWithSidebar exact path={Routes.SubjectEdit.path} component={SubjectEdit} />
//     <RouteWithSidebar exact path={Routes.SubjectView.path} component={SubjectList} />
    

//     <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
//     <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
//     <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />
    
//     <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

//     <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
//     <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
//     <Redirect to={Routes.NotFound.path} />
//   </Switch>
//   </BrowserRouter>
// );
//  }
