export const Routes = {
  // pages
  // Presentation: { path: "/dashboard/presentation" },
  DashboardOverview: { path: "/" },

  // Miload
  AdminAdd: { path: "/admin/add" },
  AdminList: { path: "/admins" },
  LocationList: { path: "/locations" },
  LocationAdd: { path: "/location/add" },
  LoadList: { path: "/loads" },
  LoadAdd: { path: "/load/add" },
  // // users
  ShipperList: { path: "/shippers" },
  ShipperView: { path: "/shipper/:id" },

  CompanyList: { path: "/companies" },
  CompanyView: { path: "/company/:id" },

  VehicleOwnerList: { path: "/owners" },
  DriverList: { path: "/drivers" },
  // Miload

  ComplaintList: { path: "/complaints" },
  EnquiriesList: { path: "/enquiries" },
  UserList: { path: "/users" },
  UserView: { path: "/user/:id" },
  EditAdminDetails: { path: "/admin-details" },

  SitterView: { path: "/driver/:id" },
  ChatView: { path: "/chatview/:variant/:id/:user" },
  TeacherList: { path: "/teacher" },
  TeacherListUnverified: { path: "/teacher/unverified" },
  TeacherView: { path: "/teacher/:id" },

  JobList: { path: "/jobs/posted" },
  JobPendingList: { path: "/jobs/pending" },
  JobOngoingList: { path: "/jobs/ongoing" },
  JobCompletedList: { path: "/jobs/completed" },
  JobCancelledList: { path: "/jobs/cancelled" },
  JobView: { path: "/job/:id" },

  SubjectAdd: { path: "/subject/add" },
  SubjectEdit: { path: "/subject/:id" },
  SubjectView: { path: "/subjects" },

  CategoryView: { path: "/categories" },
  CategoryAdd: { path: "/category/add" },
  CategoryEdit: { path: "/category/:id" },

  AdvertisementView: { path: "/advertisements" },
  AdvertisementAdd: { path: "/advertisement/add" },
  AdvertisementEdit: { path: "/advertisement/:id" },

  // ManufacturerView: { path: "/amenities" },
  // ManufacturerAdd: { path: "/amenity/add" },
  // ManufacturerEdit: { path: "/amenity/:id" },

  IndustryTypeView: { path: "/industry-types" },
  IndustryTypeAdd: { path: "/industry-type/add" },
  IndustryTypeEdit: { path: "/industry-type/:id" },

  InsuranceListView: { path: "/insurance-quotes" },
  FinanceListView: { path: "/finance-quotes" },
  TransportationListView: { path: "/transportation-quotes" },

  UserQueryView: { path: "/user-queries" },

  CountryAdd: { path: "/country/add" },
  CountryEdit: { path: "/country/:id" },
  CountryView: { path: "/countries" },

  Signin: { path: "/signin" },
  Lock: { path: "/auth/lock" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },

  // Transactions: { path: "/transactions" },
  // Settings: { path: "/settings" },
  // Upgrade: { path: "/upgrade" },
  // BootstrapTables: { path: "/tables/bootstrap-tables" },
  // Billing: { path: "/examples/billing" },
  // Invoice: { path: "/examples/invoice" },
  // // Signin: { path: "/examples/sign-in" },
  // Signup: { path: "/examples/sign-up" },
  // ForgotPassword: { path: "/examples/forgot-password" },
  // ResetPassword: { path: "/examples/reset-password" },

  // // docs
  // DocsOverview: { path: "/documentation/overview" },
  // DocsDownload: { path: "/documentation/download" },
  // DocsQuickStart: { path: "/documentation/quick-start" },
  // DocsLicense: { path: "/documentation/license" },
  // DocsFolderStructure: { path: "/documentation/folder-structure" },
  // DocsBuild: { path: "/documentation/build-tools" },
  // DocsChangelog: { path: "/documentation/changelog" },

  // // components
  // Accordions: { path: "/components/accordions" },
  // Alerts: { path: "/components/alerts" },
  // Badges: { path: "/components/badges" },
  // Widgets: { path: "/widgets" },
  // Breadcrumbs: { path: "/components/breadcrumbs" },
  // Buttons: { path: "/components/buttons" },
  // Forms: { path: "/components/forms" },
  // Modals: { path: "/components/modals" },
  // Navs: { path: "/components/navs" },
  // Navbars: { path: "/components/navbars" },
  // Pagination: { path: "/components/pagination" },
  // Popovers: { path: "/components/popovers" },
  // Progress: { path: "/components/progress" },
  // Tables: { path: "/components/tables" },
  // Tabs: { path: "/components/tabs" },
  // Tooltips: { path: "/components/tooltips" },
  // Toasts: { path: "/components/toasts" },
  // WidgetsComponent: { path: "/components/widgets" }
};
