import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'

import { Image,ResponsiveEmbed } from '@themesberg/react-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);

const ImageRow =(props) => (
  <tr>
  <th scope="row">{props.lable}</th>
    <td>
      <Image height="150px" variant="top" src={props.value} />
    </td>
</tr>
);



const ShowSitterDetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="Name"
    value={props.name} />
    
    <MyRow 
    lable="Email"
    value={props.email} />

  <MyRow 
    lable="Email verified"
        value={(props.verified) ? 'Verified' : 'Not Verified'} />
      

  <MyRow 
    lable="Address"
        value={props.address} />
      

  <MyRow 
    lable="Zip Code"
        value={props.zip_code} />
      

  <MyRow 
    lable="Wallet Balance"
        value={props.wallet_balance} />
      
  
      <MyRow 
    lable="Bank Details Added"
        value={(props.onboarding) ? 'Yes' : 'No'} />
      
      {(props.review_avg)?<MyRow  lable="Average Rating" value={props.review_avg} />:''}
      
      

      <MyRow 
    lable="Hourly Rate"
        value={props.hourly_rate} />
      
{/* 
      <ImageRow 
    lable="Signature"
        value={props.signature} /> */}
        

      <MyRow 
    lable="About"
        value={props.details} />
  
      <MyRow 
    lable="Total Jobs"
        value={props.jobs_count} />
      
      <MyRow 
    lable="Sitter Schools"
        value={(props.sitter_schools.length < 1) ? 'No sitter_schools' : props.sitter_schools.map(t => <RowElement value={ t.school_name} />)} />
      
      <MyRow 
    lable="References"
        value={(props.reference.length < 1) ? 'No References' : props.reference.map(t => <RowElement value={'Name : ' + t.name + ', Phone : ' + t.phone} />)} />
      

      
    
    
{/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}
  
    
    {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}
 
 

 <br></br>
 


  </tbody>
</table>
)

export default ShowSitterDetails


