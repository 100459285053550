import React from 'react'
import { Icon, Label, Menu, Table} from 'semantic-ui-react'
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import API from './../../config/Api';



class ChatView extends React.Component
{

  constructor(props){
    super(props);
    this.state={
      ChatList:[],
      FromUser:null,
      ToUser:null
    }
  }

  componentDidMount(){

    document.title = "Dashboard Overview"
    let data = {chat_id:this.props.match.params.id};
    
    API.post('/chat',data).then((response)=> {
      console.log(response.data)
     this.setState({
      ChatList:response.data.data,
     })
    })

  }

  render(){
    return (
      <Row className="mt-4"  >
      <Col xs={12} xl="12" >
        <Card border="light" className="bg-white shadow-sm mb-4 ">
        <Card.Header>
          {(this.props.match.params.variant == 'teacher') ? <React.Fragment>
          <span className='h5'>Teacher Messages</span>
          <span className='h5 heading-right-message'>
            Student Messages</span>
          </React.Fragment> : <React.Fragment>
          <span className='h5 heading-right-message'>Teacher Messages</span>
          <span className='h5 '>
            Student Messages</span>
          </React.Fragment> }
       
            </Card.Header>
        <Card.Body>
            { this.state.ChatList.map((data) => {
              
              let ClassesName = 'message my-message';
             
              if(this.props.match.params.user == data.sender_id){
                ClassesName = 'message to-message';
              }
              
              return (<React.Fragment>
                      <div className={ClassesName} >
                        <p> {data.message}</p>
                           <p className ='time'>  {`${data.created_at.substr(0,10)}  ${data.created_at.substr(11,15)}`}</p>
                       </div>
              <br></br>
                </React.Fragment>
              );
            }) }
          
         
         
      

        </Card.Body>
          </Card>
            </Col>
          </Row>
    )
  }
}

export default ChatView
