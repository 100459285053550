import Amplify from "@aws-amplify/core";
import Storage from "@aws-amplify/storage";
// ENABLE DEBUG MODE
//window.LOG_LEVEL = "DEBUG";
export function configureAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: "ap-south-1:3263f2a4-9bab-41d5-bea2-43975aba5558",
      region: "ap-south-1",
      userPoolId: "ap-south-1_c8aJzMZqK",
      mandatorySignIn: false,
      userPoolWebClientId: "7vril42r5v6q0gp3p3gpl21ec3",
    },
    Storage: {
      bucket: "miloadapp47a39c2ae0314264824214b5a4ef023e114005-dev",
      region: "ap-south-1",
      identityPoolId: "ap-south-1:3263f2a4-9bab-41d5-bea2-43975aba5558",
    },
  });
}

//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: "ap-south-1",
    identityPoolId: "ap-south-1:3263f2a4-9bab-41d5-bea2-43975aba5558",
  });
}
