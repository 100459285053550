import axios from "axios";

let axios_request = axios.create({
  baseURL: `https://api.mi-load.com/api/admin/`,
  // baseURL:  `http://localhost:3005/api/admin/`,
  timeout: 10000,
});

const requestHandler = (request) => {
  request.headers.Authorization = "Bearer " + localStorage.getItem("api_token");

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 404) {
    window.alert("Invalid Request");
  }

  return response;
};

function manageErrorConnection(err) {
  const originalConfig = err.config;
  if (err.response && err.response.status == 404) {
    alert(err.response.data.message);
    return Promise.reject(err);
  }
  // else if (err.response && err.response.status == 404 && !originalConfig._retry) {
  //   originalConfig._retry = true;

  //   return axios_request(originalConfig);
  // }
  else if (err.response && err.response.status == 403) {
    localStorage.setItem("token", false);
    localStorage.setItem("api_token", "");
    alert("Session Expired");
    window.location.reload();
    return Promise.reject(err);
  } else {
    return Promise.reject(err);
  }
}

axios_request.interceptors.request.use(
  (request) => requestHandler(request),
  manageErrorConnection
);

axios_request.interceptors.response.use(
  (response) => responseHandler(response),
  manageErrorConnection
);

export default axios_request;
