import React from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Error, Success } from "../../components/Alerts";
import Api from "../../config/Api";
import { Redirect } from "react-router-dom";
import { uploadFile } from "react-s3";
import { Braket } from "aws-sdk";
const MyInputs = (props) => {
  switch (props.type) {
    case "file":
      return (
        <Col md={6} key={props.index} className="mb-3">
          <Form.Label>{props.lable}</Form.Label>
          <Form.Control
            onChange={props.submithandle}
            name={props.name}
            type={props.type}
            placeholder={`Enter your ${props.lable}`}
          />

          <Error message={props.error} />
        </Col>
      );
      break;

    case "checkbox":
      return (
        <Col md={6} key={props.index} className="mb-3">
          <Form.Label>{props.lable}</Form.Label>
          <Form.Check
            label="Active"
            type="checkbox"
            checked={props.value}
            onChange={props.submithandle}
            name={props.name}
            placeholder={`Enter your ${props.lable}`}
          />

          <Error message={props.error} />
        </Col>
      );
      break;

    default:
      return (
        <Col md={6} key={props.index} className="mb-3">
          <Form.Label>{props.lable}</Form.Label>

          <Form.Control
            onChange={props.submithandle}
            name={props.name}
            type={props.type}
            value={props.value}
            placeholder={`Enter your ${props.lable}`}
          />
          <Error message={props.error} />
        </Col>
      );
  }
};

const form = [
  {
    lable: "Category Name",
    name: "category_name",
    type: "text",
    required: false,
  },
  {
    lable: "Category Image",
    name: "category_image",
    type: "file",
    required: false,
  },
  {
    lable: "Category Description ",
    name: "category_description",
    type: "text",
    required: false,
  },
  {
    lable: "Category Status",
    name: "category_status",
    type: "checkbox",
    required: false,
  },
];
const config = {
  bucketName: "miload",
  dirName: "admin/category",
  region: "ap-south-1",
  accessKeyId: "AKIAS5K4GK7NZ567JZPS",
  secretAccessKey: "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",
};

class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_id: "",
      category_name: "",
      category_image: "",
      category_status: true,
      category_description: "",
      error: {},
      success: false,
      loading: false,
      success: false,
    };
  }

  componentDidMount() {
    let data = { params: { id: this.props.match.params.id } };
    console.log("id is : ", this.props.match.params.id);
    Api.get("category", data)
      .then((response) => {
        console.log(response);
        this.setState({
          category_id: response.data.data.id,
          category_name: response.data.data.name,
          category_image: response.data.data.image,
          category_status: response.data.data.active,
          category_description: response.data.data.description,
        });
        console.log({
          category_id: response.data.data.id,
          category_name: response.data.data.name,
          category_image: response.data.data.image,
          category_description: response.data.data.description,
        });
      })
      .catch((e) => {
        // this.setState({
        // error:e.response.data.message
        // })
      });
  }

  handleChangeinput = (e) => {
    // e.preventDefault();
    var InputName = e.target.name;
    var InputType = e.target.type;

    console.log(InputName, [InputType, e.target.checked]);

    switch (InputType) {
      case "file":
        this.setState({ loading: true });
        uploadFile(e.target.files[0], config)
          .then((data) =>
            this.setState({ [InputName]: data.location, loading: false })
          )
          .catch((err) => console.error(err));
        break;

      case "checkbox":
        // console.log(InputName,e.target.checked)
        console.log("InputName", "e.target.checked");

        this.setState({ [InputName]: e.target.checked });

        break;

      default:
        this.setState({ [InputName]: e.target.value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let Errors = [];
    this.setState({ process: true });
    form.map((value, index) => {
      if (value.required) {
        console.log(this.state[value.name]);
        if (this.state[value.name] == "") {
          Errors[value.name] = `Please enter ${value.lable}`;
          console.log(value.name, Errors[value.name]);
          this.state.error[value.name] = Errors[value.name];
        } else {
          delete this.state.error[value.name];
        }
      }
      if (form.length == index + 1) {
        this.setState({ process: false });
      }
    });

    if (Object.keys(this.state.error).length) {
      console.log(" We hove errors ", this.state.error);
    } else {
      this.setState({ error: {} });
      var data = {
        category_name: this.state.category_name,
        category_status: this.state.category_status,
        category_image: this.state.category_image,
        category_description: this.state.category_description,
      };
      console.log("with", data);
      Api.put("category", data, { params: { id: this.props.match.params.id } })
        .then((response) => {
          this.setState({ success: true });
        })
        .catch((e) => {
          let message = e.response.data.message;
          this.setState({ error: { submit: message } });
        });
    }
  };

  render() {
    if (this.state.success) {
      return (
        <Redirect
          to={{
            pathname: "/categories",
            state: "Subejct Upadetd Successfully",
          }}
        />
      );
    }
    return (
      <Row className="mt-4">
        <Col xs={8} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <Form>
                <Row>
                  {form.map((value, index) => {
                    return (
                      <MyInputs
                        index={index}
                        submithandle={this.handleChangeinput.bind(this)}
                        name={value.name}
                        lable={value.lable}
                        type={value.type}
                        value={this.state[value.name]}
                      />
                      //   <Col md={6} key={index} className="mb-3">
                      //     <Form.Label>{value.lable}</Form.Label>
                      //     { (value.type == 'file') ?
                      //       <Form.Control onChange={this.handleChangeinput}  name={value.name}
                      //       // `value={(value.type =='file' ? '' : this.state[value.name] )}`}
                      //        type={value.type}  placeholder={`Enter your ${value.lable}`} />
                      //      :
                      //      <Form.Control onChange={this.handleChangeinput}  name={value.name}

                      //       type={value.type}  value={this.state[value.name]} placeholder={`Enter your ${value.lable}`} />
                      //      }

                      //     <Error message={this.state.error[value.name]} />
                      // </Col>
                    );
                  })}
                  <Col md={12} className="mt-4">
                    <Button
                      variant="primary"
                      onClick={this.handleSubmit}
                      type="button"
                    >
                      Update{" "}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4} xl={4}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <img
                src={this.state.category_image}
                alt="Girl in a jacket"
                width="100%"
              ></img>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default EditCategory;
