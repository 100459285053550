import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch,faEye,faEllipsisH,faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import PlaceholderList from './../components/Placeholder';


import API from '../../config/Api';


import { Routes } from "../../routes";
import subjects from './../../data/subjects'



class SubjectList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true

    }
  }

  componentDidMount(){
     API.get(`list-category`).then((response) => {
     
      this.setState({
      list:response.data.data,
      loading:false
      })
    });
    
  }

  render(){

    const totalsubjects = this.state.list.length;
    const TableRow = (props) => {
    const { id,c_id,name,createdAt } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">
              <Card.Link as={Link} to={`/subject/${id}`} className="fw-normal">
            {c_id}
             </Card.Link>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(createdAt).format('Do MMM YY')}
          </span>
        </td>
        <td>
            <Card.Link as={Link} to={`/subject/${id}`} className="fw-normal">
            <Button ><FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
             </Button>
             </Card.Link>
           
        </td>
      </tr>
    );
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Category List</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Category List</h4>
          <p className="mb-0">All Categories List</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

    
     <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Category</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
          { (this.state.loading == true) ? <PlaceholderList colspan='4' /> : this.state.list.map(t => <TableRow  {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalsubjects}</b> out of <b>{totalsubjects}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
     
    </>);
  }

}


export default SubjectList



