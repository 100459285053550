import React from "react";
import { Icon, Label, Menu, Table } from "semantic-ui-react";

import { Image, ResponsiveEmbed } from "@themesberg/react-bootstrap";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ImageLoad from "../../components/ImageLoad.jsx";

const MyRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>{props.value}</td>
  </tr>
);

const RowElement = (props) => (
  <span>
    {props.value}
    <br></br>
  </span>
);

const ImageRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>
      <ImageLoad ImageId={props.value} />{" "}
      {/* <Image height="150px" variant="top" src={props.value} /> */}
    </td>
  </tr>
);

const ShowCompanyDetails = (props) => (
  <table class="table">
    <tbody>
      <MyRow lable="Name" value={props.data.name} />

      <MyRow lable="Email" value={props.data.email} />

      <MyRow
        lable="Email verified"
        value={props.data.verified ? "Verified" : "Not Verified"}
      />

      <MyRow lable="Phone" value={props.data.phone} />

      <MyRow
        lable="Document Uploaded"
        value={props.data.onboarding_status ? "Uploaded" : "Not Uploaded"}
      />

      <MyRow
        lable="Account Status"
        value={props.data.active ? "Active" : "Inactive"}
      />

      {/* <MyRow
        lable="References"
        value={
          props.data.references.length < 1
            ? "No References"
            : props.data.references.map((t) => (
                <RowElement
                  value={
                    "Company Name : " + t.company_name + ", Phone : " + t.phone
                  }
                />
              ))
        }
      /> */}

      <MyRow
        lable="Documents"
        value={
          props.data.documents.length < 1
            ? "No Documents"
            : props.data.documents.map((t) => (
                <>
                  <RowElement value={"Document Type : " + t.document_type} />
                  <ImageRow
                    lable="Document Preview : "
                    value={t.document_link}
                  />
                </>
              ))
        }
      />

      {props.data.role == 4 && props.data.truck_detail ? (
        <MyRow
          lable="Truck"
          value={
            props.data.truck_detail.model +
            " | " +
            props.data.truck_detail.registration_number +
            " | " +
            props.data.truck_detail.truck_number +
            " | " +
            props.data.truck_detail.year
          }
        />
      ) : (
        ""
      )}

      {props.data.role == 4 &&
      props.data.truck_detail &&
      props.data.truck_detail.trailers ? (
        <MyRow
          lable="Trailers"
          value={
            props.data.truck_detail.trailers.length < 1
              ? "No Trailers"
              : props.data.truck_detail.trailers.map((t) => (
                  <>
                    <RowElement value={"Model : " + t.model} />
                    <RowElement
                      value={"Registration Number : " + t.registration_number}
                    />
                    <RowElement
                      value={"Trailer Number : " + t.trailer_number}
                    />
                    <br />
                  </>
                ))
          }
        />
      ) : (
        ""
      )}

      {/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}

      {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}

      <br></br>
    </tbody>
  </table>
);

export default ShowCompanyDetails;
