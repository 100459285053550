// import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import S3Bucket from "../services/S3bucket";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const customId = "custom-id-yes";

function ImageLoad(props) {
  //   const [getCount,setCount] = useState([]);
  const [GetImage, SetImage] = useState();
  const [Image, setImage] = useState(props.ImageId);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPdf, setPdf] = useState(false);

  useEffect(() => {
    LoadImage(props.ImageId);
  }, [props.ImageId]);

  function LoadImage(ImageId) {
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: ImageId,
      Expires: 60 * 60,
    };
    S3Bucket.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error retrieving object:", err);
      } else {
        SetImage(url);
        console.log("Object retrieved successfully:", url);
      }
    });
  }
  function onDocumentLoadSuccess() {
    setNumPages(1);
  }

  return isPdf ? (
    // <Document
    //   height={300}
    //   file={GetImage}
    //   onLoadError={() => {
    //     setPdf(false);
    //   }}
    //   onLoadSuccess={onDocumentLoadSuccess}
    // >
    //   <Page pageNumber={numPages} />
    // </Document>
    <a target={"_blank"} href={GetImage}>
      <div>Get File</div>
    </a>
  ) : (
    <>
      <a target={"_blank"} href={GetImage}>
        {" "}
        <img
          onError={() => {
            setPdf(true);
          }}
          height={props.height}
          width={props.width}
          className={props.className}
          src={GetImage}
          alt="File Cannot be open "
        />{" "}
      </a>{" "}
    </>
  );
}

export default ImageLoad;
