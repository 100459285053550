import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import ShowDetails from '../components/Showdetails';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { Image } from 'semantic-ui-react'
import PlaceholderList from './../components/Placeholder';  
import ChatList from './../components/Chatlist';  


class TeacherView extends React.Component{

  constructor(props){
    super(props);
    this.state={
      data:[],
      error:'',
      success:false,
      loading:true
    } 
  }

  componentDidMount(){

    let data = {teacher_id:this.props.match.params.id};
    Api.post('teacher/details',data).then((response)=>{
      console.log(response);
      this.setState({
        data:response.data.data,
        loading:false
      })
    }).catch((e)=> {
        this.setState({
        error:e.response.data.message,
        loading:false
      })
    })
    // console.log()

  }

  handlename = e=> {this.setState({subjectname:e.target.value})}
  handleSubmit = e => {
    
    if(this.state.subjectname == ''){
      this.setState({error:"Please enter subject name"});
    }else{
      this.setState({error:''});
      var data = {
        name:this.state.subjectname
      }
      Api.put('subject',data,{params:{id:this.props.match.params.id}}).then((response)=> {
        this.setState({success:true})
      }).catch((e)=>{
        let message = e.response.data.message;
        this.setState({error:message})
      })

    }

  }

  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col>
        <Col xs={12} xl={6}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Teachers Details</Card.Header>
              <Card.Body>
              <ShowDetails  
              variant='teacher'
              name={this.state.data.name }
              email={this.state.data.email }
              phone={this.state.data.phone }
              subjects={this.state.data.subject_list.map((subject,index)=> {
                return subject.subject + ((this.state.data.subject_list.length - 1) == index  ? '' : ' | ' );  
              }) }
              avg_rating = {this.state.data.avg_rating}
              country={this.state.data.country }
              description={this.state.data.description }
              nationality={this.state.data.nationality }
               />
              </Card.Body>
            </Card>
        </Col>
        <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header> Chats With Students</Card.Header>
              <Card.Body>

              <ChatList variant={2} chat={this.state.data.chats }  ></ChatList>
              </Card.Body>
            </Card>
        </Col>

      </Row>);
  }

}



export default TeacherView;
