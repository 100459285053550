import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faSearch,
  faEye,
  faEllipsisH,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import moment from "moment-timezone";
import PlaceholderList from "../components/Placeholder";
import API from "../../config/Api";

import { Routes } from "../../routes";
import subjects from "../../data/subjects";

class DriverList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 4,
      user_type: "Driver",
      list: [],
      loading: true,
      page: 1,
      DisableLoadmore: false,
    };
  }

  componentDidMount() {
    document.title = `Users list | ${process.env.REACT_APP_NAME}`;
    API.get(`/users`, {
      params: {
        page: this.state.page,
        role: this.state.role,
      },
    }).then((response) => {
      let PageNumber = this.state.page;
      console.log(response.data.data);
      this.setState({
        list: response.data.data,
        page: PageNumber + 1,
        loading: false,
      });

      if (response.data.last_page) {
        this.setState({
          DisableLoadmore: true,
        });
      }
    });
  }

  onClickActive() {
    let id = arguments[0];

    let Confirm = window.confirm("Do you want to Change Status");

    if (Confirm) {
      this.setState({
        loading: true,
      });
      API.post("users/change-status", { user_id: id })
        .then((response) => {
          API.get(`/users`, {
            params: {
              page: 1,
              role: this.state.role,
            },
          }).then((response) => {
            console.log(response);
            if (response.data.data.length == 0 || response.data.last_page) {
              this.setState({
                DisableLoadmore: true,
              });
            }
            let PageNumber = 1;
            this.setState({
              list: response.data.data,
              page: PageNumber + 1,
              loading: false,
            });
          });
        })
        .catch((e) => {
          alert(e.response.data.message);
        });
    }
  }

  HandleLoadMore = (e) => {
    API.get(`/users`, {
      params: {
        page: this.state.page,
        role: this.state.role,
      },
    }).then((response) => {
      if (response.data.data.length == 0) {
        this.setState({
          DisableLoadmore: true,
        });
      }
      let PageNumber = this.state.page;
      this.setState({
        list: [...this.state.list, ...response.data.data],
        page: PageNumber + 1,
        loading: false,
      });
    });
  };

  render() {
    const totalsubjects = this.state.list.length;

    const TableRow = (props) => {
      const { id, user_number, name, email, active, verified, createdAt } =
        props;

      return (
        <tr>
          <td>
            <span className="fw-normal">{user_number}</span>
          </td>
          <td>
            <span className="fw-normal">{email}</span>
          </td>
          <td>
            <span className="fw-normal">
              {verified ? "Verified" : "Not Verified"}
            </span>
          </td>
          <td>
            <span className="fw-normal">{name}</span>
          </td>

          <td>
            <span className="fw-normal">
              {moment(createdAt).format("Do MMM YY")}
            </span>
          </td>

          <td>
            <span className="fw-normal">
              {active == 0 ? (
                <Button
                  onClick={this.onClickActive.bind(this, id)}
                  variant="success"
                >
                  {" "}
                  Activate{" "}
                </Button>
              ) : (
                <Button
                  onClick={this.onClickActive.bind(this, id)}
                  variant="danger"
                >
                  {" "}
                  Deactivate{" "}
                </Button>
              )}
            </span>
          </td>
          <td>
            <Card.Link as={Link} to={`/company/${id}`} className="fw-normal">
              <Button> View </Button>
            </Card.Link>
          </td>
        </tr>
      );
    };

    let alert = this.props.location.state ? this.props.location.state : "";

    return (
      <>
        {}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>
                {this.state.user_type} List
              </Breadcrumb.Item>
            </Breadcrumb>
            <h4>{this.state.user_type} List</h4>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0"></div>
        </div>

        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">ID</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Email Verification</th>
                  <th className="border-bottom">Name</th>
                  <th className="border-bottom">Created At</th>
                  <th className="border-bottom">Activate/Deactivate</th>
                  <th className="border-bottom">View</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loading == true ? (
                  <PlaceholderList colspan="7" />
                ) : (
                  this.state.list.map((t) => <TableRow {...t} />)
                )}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Nav>
                {/* <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination> */}
              </Nav>
              <small className="fw-bold">
                Showing <b>{totalsubjects}</b> out of <b>{totalsubjects}</b>{" "}
                entries
              </small>
              {this.state.DisableLoadmore ? (
                ""
              ) : (
                <Button onClick={this.HandleLoadMore}>Load more</Button>
              )}
            </Card.Footer>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default DriverList;
